import { TimeZone } from "./services/settings/settings.model";

const moment = require('moment-timezone');
let currentTimezone = '';

// Override moment.js to use the +03:00 timezone
moment.fn.myFormat = function (format) {
    return this.add(0, 'hours').format(format);
};

export const getCurrentTimezone = () => {
    return currentTimezone;
}

export const overrideMomentTimezone = (timezone:TimeZone) => {
    const timeZone = timezone.name;
    currentTimezone = timeZone;
    moment.fn.myFormat = function (format) {
        return this.tz(timeZone).format(format);
        // return this.add(hours+localOffset, 'hours').format(format);
    };
}

export default moment;
