import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';
import { CompanyTag, ManageSubCompanyService, SubCompanyResponse } from 'app/services/manageSubCompany/manage-sub-company.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { TypeOfAccount } from 'app/services/company/company.model';

interface Column {
  field: string
  header: string
  sortable?: boolean
  hidden?: boolean
  alterFunction?: (value: any) => any,
  classStyle?: string,

  suffix?: string,
  specialId?: string,
  metadata?: string
}

type RowData = SubCompanyResponse['companies'][0];

type TrialPeriodModalData = {
  rowData: RowData,
  days: number,
}

type ModalData = {
  rowData: RowData,
  reason: string,
  proEnds?: {
    enabled: boolean,
    endTime: Date
  }
}


@Component({
  selector: 'app-manage-sub-company',
  templateUrl: './manage-sub-company.component.html',
  styleUrls: ['./manage-sub-company.component.css']
})
export class ManageSubCompanyComponent implements OnInit {

  private subscriptions = new Subscription();

  public data: SubCompanyResponse = { result: 'SUCCESS', companies: [] };

  // dt1 id element from html
  @ViewChild('dt1', { static: false }) dt1: Table;
  @ViewChild('dt1InputText', { static: false }) dt1InputText: ElementRef<HTMLInputElement>;

  public chartData: any;
  public chartOptions: any;
  public tempData = Array(8).fill(0);

  public trialPeriodModalData: TrialPeriodModalData | null = null;
  public proModalData: ModalData | null = null;
  public blockModalData: ModalData | null = null;
  public proTypes = [{ label: 'BASIC', value: 'BASIC' },
  { label: 'PRO', value: 'PRO' },
  { label: 'REQUESTED', value: 'REQUESTED' }];

  private baseCompanyTags = 
    [{ label: 'TAG_BASIC', value: 'TAG_BASIC', classStyle: 'bg-slate-500 text-white' },
  { label: 'TAG_PRO', value: 'TAG_PRO', classStyle: 'bg-tachosafe-500 text-white' },
  { label: 'TAG_TRIAL', value: 'TAG_TRIAL', classStyle: 'bg-orange-500 text-white' },
  { label: 'TAG_TRIAL_PRO', value: 'TAG_TRIAL_PRO', classStyle: 'bg-orange-500 text-white' },
  { label: 'TAG_REQUESTED', value: 'TAG_REQUESTED', classStyle: 'bg-yellow-400 text-slate-800' },
  { label: 'TAG_BLOCKED', value: 'TAG_BLOCKED', classStyle: 'bg-red-500 text-white' },
  { label: 'TAG_PARTNER', value: 'TAG_PARTNER', classStyle: 'bg-green-500 text-white' }]


  public companyTags = [];

  public totals = {
    driverCount:0,
    vehicleCount:0,
    fileCount:0,
    fileCountInPrevMonth:0,
  }

  public cols: Column[] = [
    // { field: 'blocked', header: '', sortable: true, specialId: 'blocked', classStyle: 'w-[50px]' },
    { field: 'actions', header: '', specialId: 'actions', classStyle: 'w-[50px]' },
    { field: 'name', header: 'manageSubCompany.table.header.clientName', classStyle: '', sortable: true, specialId: 'name' },
    // { field: 'proReq', header: 'manageSubCompany.table.header.pro', sortable: true, specialId: 'proReq' },
    // { field: 'id', header: '', classStyle: 'hidden' },
    { field: 'tags', header: '', sortable: true, specialId: 'tags', classStyle: '' },
    { field: 'driverCount', header: 'manageSubCompany.table.header.driver', sortable: true, classStyle: 'justify-end' },
    { field: 'vehicleCount', header: 'manageSubCompany.table.header.vehicle', sortable: true, classStyle: 'justify-end' },
    { field: 'fileCount', header: 'manageSubCompany.table.header.file', sortable: true, classStyle: 'justify-end' },
    { field: 'fileCountInPrevMonth', header: 'manageSubCompany.table.header.filePrevMonth', sortable: true, classStyle: 'justify-end' },
    // { field: 'priceInfos.1.count', metadata: '1', specialId: 'priceInfo', header: 'Driver', sortable: true, classStyle: 'justify-end'},
    // { field: 'priceInfos.2.count', metadata: '2', specialId: 'priceInfo', header: 'Vehicle', sortable: true, classStyle: 'justify-end'},
    // { field: 'priceInfos.3.count', metadata: '3', specialId: 'priceInfo', header: 'File', sortable: true, classStyle: 'justify-end' },
    // { field: 'calculated.totalDiscount', header: 'Discount', sortable: true, specialId: 'totalDiscount', classStyle: 'justify-end font-bold', suffix: '€' },
    // { field: 'calculated.totalPriceWithoutDiscount', header: 'Price', sortable: true, classStyle: 'justify-end font-bold', suffix: '€' },http://localhost:4200/#/dashboard
    // { field: 'calculated.totalPayment', header: 'Total Payment', sortable: true, classStyle: 'justify-end font-bold', suffix: '€' },
  ];

  userIsAdmin = false;
  clientId = 0;
  loading = false;

  resetUsageRecordModalData: {
    rowData: any;
    timestamp: number;
    value: 0;
  } = null;

  pauseSubscriptionModalData: {
    rowData: any;
    timestamp: Date;
  } = null;

  constructor(
    private readonly authService: AuthService,
    private readonly manageSubCompanyService: ManageSubCompanyService,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService
  ) { }

  removeTagPrefix(tag: string) {
    return tag.replace('TAG_', '');
  }

  trans(name: string) {
    return this.translate.instant(name) || name;
  }

  containsTag(rowData: RowData, tag: string) {
    return rowData.tags.includes(tag as CompanyTag);
  }

  ngOnInit(): void {
    this.userIsAdmin = this.authService.getLoggedInUser().clientAdmin;
    this.clientId = this.authService.getLoggedInUser().clientId;
    this.companyTags = [...this.baseCompanyTags];
    if(this.clientId === 21){
      this.companyTags.push({ label: 'TAG_STRIPE', value: 'TAG_STRIPE', classStyle: 'bg-blue-500 text-white' })
      this.companyTags.push({ label: 'TAG_NORMAL', value: 'TAG_NORMAL', classStyle: 'bg-blue-500 text-white' })
      
    }
    const filter = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (!!filter) {
      setTimeout(() => {
        (this.dt1 as any as Table).filters['id'] = {
          value: filter,
          matchMode: 'equals'
        }
      }, 1000);
    }
    const companyIdSubscription = this.authService.currentCompanyId.subscribe(async (companyId) => {
      this.loading = true;
      const response = await this.manageSubCompanyService.getSubCompanies(this.clientId===21);
      this.loading = false;
      this.data.companies = response.companies.sort((a, b) => a.name.localeCompare(b.name));
      this.data.result = response.result;
      this.calculateTotals(this.data.companies);
      // console.log(this.data.companies);
      // if(filter && filter.length > 0) {
      //   const lastChar = filter[filter.length - 1];
      //   console.log(this.dt1)
      //   this.dt1InputText.nativeElement.value = filter.slice(0, filter.length - 1);
      //   this.dt1InputText.nativeElement.dispatchEvent(new KeyboardEvent('keyup', { key: lastChar }));
      // }
      // this.drawChart();
    });
    this.subscriptions.add(companyIdSubscription);
  }

  calculateTotals(values:any) {
    this.totals.driverCount = values.reduce((acc, company) => acc + company.driverCount, 0);
    this.totals.vehicleCount = values.reduce((acc, company) => acc + company.vehicleCount, 0);
    this.totals.fileCount = values.reduce((acc, company) => acc + company.fileCount, 0);
    this.totals.fileCountInPrevMonth = values.reduce((acc, company) => acc + company.fileCountInPrevMonth, 0);
  }

  //after view init
  ngAfterViewInit() {
    this.dt1.onFilter.subscribe(() => {
      this.calculateTotals(this.dt1.filteredValue);
    });
  }

  applyTagFilter(tag: string) {
    this.dt1.filterGlobal(tag, 'contains')
  }

  formatDate(timestamp: number) {
    return new Date(timestamp).toLocaleDateString();
  }

  trialPeriodDaysChanged(){
    if(this.trialPeriodModalData.days < 1){
      this.trialPeriodModalData.days = 1;
    }
    if(this.trialPeriodModalData.days > 31){
      this.trialPeriodModalData.days = 31;
    }
  }


  async drawChart() {
    this.chartOptions = {
      legend: {
        display: true,
        labels: {
          fontSize: 20,
        }
      },
      animation: {
        duration: 0
      }
    }
    const proCount = this.data.companies.reduce((acc, company) => acc + (company.pro ? 1 : 0), 0);
    const documentStyle = getComputedStyle(document.body);
    this.chartData = {
      labels: [
        this.translate.instant('manageSubCompanyComponent.chartData.labels.pro'),
        this.translate.instant('manageSubCompanyComponent.chartData.labels.notPro'),
      ],

      datasets: [
        {
          data: [proCount, this.data.companies.length - proCount],
          backgroundColor: [documentStyle.getPropertyValue('--primary-color'), "#cbd5e1"],
        }
      ]
    };
  }

  cancelModal() {
    this.proModalData = null;
    this.blockModalData = null;
    this.trialPeriodModalData = null;
  }

  async enableTrialForCompany(rowData: RowData) {
    const days = this.trialPeriodModalData.days;
    try {
      this.trialPeriodModalData = null;
      await this.manageSubCompanyService.addTrialPeriod(rowData.id, days);
      rowData.tags = rowData.tags.filter(t => t !== CompanyTag.BASIC);
      rowData.tags.push(CompanyTag.PRO);
      rowData.tags.push(CompanyTag.TRIAL);
      // this.messageService.add({
      //   severity: 'success',
      //   summary: this.trans('manageSubCompany.toast.enableTrialForCompany.title'),
      //   detail: this.trans('manageSubCompany.toast.enableTrialForCompany.detail'),
      //   life: 10 * 1000
      // });
    } catch (e) {
      // this.messageService.add({
      //   severity: 'error',
      //   summary: this.trans('manageSubCompany.toast.generalError.title'),
      //   detail: this.trans('manageSubCompany.toast.generalError.detail'),
      //   life: 10 * 1000
      // });
      console.log(e);
    }
  }

  async enableProForCompany(rowData: RowData) {
    const user = this.authService.getLoggedInUser();
    const proEnds = this.proModalData.proEnds;
    try {
      this.proModalData = null;
      rowData.pro = true;
      // rowData.proReq = 'PRO';
      rowData.tags = rowData.tags.filter(t => t !== CompanyTag.BASIC);
      rowData.tags.push(CompanyTag.PRO);
      if (proEnds.enabled) {
        await this.manageSubCompanyService.enableProForCompany(rowData.id, proEnds.endTime.getTime() / 1000);
      } else {
        await this.manageSubCompanyService.enableProForCompany(rowData.id, undefined);
      }
      // this.messageService.add({
      //   severity: 'success',
      //   summary: this.trans('manageSubCompany.toast.enableProForCompany.title'),
      //   detail: this.trans('manageSubCompany.toast.enableProForCompany.detail'),
      //   life: 10 * 1000
      // });
    } catch (e) {
      // this.messageService.add({
      //   severity: 'error',
      //   summary: this.trans('manageSubCompany.toast.generalError.title'),
      //   detail: this.trans('manageSubCompany.toast.generalError.detail'),
      //   life: 10 * 1000
      // });
      console.log(e);
    }
  }

  clear(table: Table) {
    table.filterGlobal('', 'contains');
    table.clear();
    this.dt1.filters['id'] = {}
    setTimeout(() => {
      this.calculateTotals(this.data.companies);
      this.dt1.onFilter.subscribe(() => {
        this.calculateTotals(this.dt1.filteredValue);
      });
    }, 1000);
    
  }


  async disableTrialForCompany(rowData: RowData) {
    try {

      // rowData.proReq = 'BASIC';
      const res = await this.manageSubCompanyService.disableTrialForCompany(rowData.id);
      if(res.result === 'FAILED'){
        this.messageService.add({
          severity: 'error',
          summary: res.message,
          detail: res.message,
          life: 10 * 1000
        });
      }else{
        rowData.tags = rowData.tags.filter(t => t !== CompanyTag.TRIAL);
        rowData.tags.push(CompanyTag.BASIC);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async disablePendingRequest(rowData: RowData) {
    try {

      // rowData.proReq = 'BASIC';
      const res = await this.manageSubCompanyService.disablePendingRequest(rowData.id);
      if(res.result === 'FAILED'){
        this.messageService.add({
          severity: 'error',
          summary: res.message,
          detail: res.message,
          life: 10 * 1000
        });
      }else{
        rowData.pendingRequest = false;
        rowData.tags = rowData.tags.filter(t => t !== CompanyTag.REQUESTED);
        rowData.tags.push(CompanyTag.BASIC);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async disableProForCompany(rowData: RowData, reason: string, immediate = false) {
    try {
      this.proModalData = null;
      rowData.pro = false;
      // rowData.proReq = 'BASIC';
      const res = await this.manageSubCompanyService.disableProForCompany(rowData.id, reason, 'OTHER', immediate);
      if(res.result === 'FAILED'){
        this.messageService.add({
          severity: 'error',
          summary: res.message,
          detail: res.message,
          life: 10 * 1000
        });
      }else{
        rowData.tags = rowData.tags.filter(t => t !== CompanyTag.PRO && t !== CompanyTag.REQUESTED);
        rowData.tags.push(CompanyTag.BASIC);
      }
      // this.messageService.add({
      //   severity: 'success',
      //   summary: this.trans('manageSubCompany.toast.disableProForCompany.title'),
      //   detail: this.trans('manageSubCompany.toast.disableProForCompany.detail'),
      //   life: 10 * 1000
      // });
    } catch (e) {
      // this.messageService.add({
      //   severity: 'error',
      //   summary: this.trans('manageSubCompany.toast.generalError.title'),
      //   detail: this.trans('manageSubCompany.toast.generalError.detail'),
      //   life: 10 * 1000
      // });
      console.log(e);
    }
  }

  isBlocked(rowData: RowData) {
    return rowData.tags.includes(CompanyTag.BLOCKED);
  }

  blockClientClicked(rowData: RowData) {
    this.blockModalData = { rowData, reason: '' };
  }

  blockClient(rowData: RowData) {
    const reason = this.blockModalData.reason;
    this.blockModalData = null;
    rowData.blocked = 'BLOCKED';
    rowData.tags.push(CompanyTag.BLOCKED);
    rowData.blockReason = reason;
    try {
      this.manageSubCompanyService.blockClient(rowData.id, reason);
      // this.messageService.add({
      //   severity: 'success',
      //   summary: this.trans('manageSubCompany.toast.blockClient.title'),
      //   detail: this.trans('manageSubCompany.toast.blockClient.detail'),
      //   life: 10 * 1000
      // });
    } catch (error) {
      // this.messageService.add({
      //   severity: 'error',
      //   summary: this.trans('manageSubCompany.toast.generalError.title'),
      //   detail: this.trans('manageSubCompany.toast.generalError.detail'),
      //   life: 10 * 1000
      // });
    }
  }

  unblockClient(rowData: RowData) {
    this.blockModalData = null;
    rowData.blocked = 'NOT_BLOCKED';
    rowData.tags = rowData.tags.filter(t => t !== CompanyTag.BLOCKED);
    rowData.blockReason = '';
    try {
      this.manageSubCompanyService.unblockClient(rowData.id);
      // this.messageService.add({
      //   severity: 'success',
      //   summary: this.trans('manageSubCompany.toast.unblockClient.title'),
      //   detail: this.trans('manageSubCompany.toast.unblockClient.detail'),
      //   life: 10 * 1000
      // });
    } catch (error) {
      // this.messageService.add({
      //   severity: 'error',
      //   summary: this.trans('manageSubCompany.toast.generalError.title'),
      //   detail: this.trans('manageSubCompany.toast.generalError.detail'),
      //   life: 10 * 1000
      // });
    }
  }

  proSwitchClicked(rowData: RowData) {
    console.log(rowData);
    //current date + 20 days
    const endTime = new Date();
    endTime.setDate(endTime.getDate() + 20);

    this.proModalData = { rowData, reason: '', proEnds: { enabled: false, endTime: endTime } };
  }

  addTrialPeriodClicked(rowData: RowData) {
    this.trialPeriodModalData = { rowData, days: 20 };
  }

  getValue(rowData: RowData, column: Column) {
    const value = this.getValueByPath(rowData, column.field)
    if (column.alterFunction) {
      return column.alterFunction(value)
    }
    return value
  }

  getValueByPath(obj: RowData, path: string) {
    const keys = path.split('.');
    let value = obj;

    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        // Return undefined if any key in the path is missing
        return undefined;
      }
    }
    return value;
  }

  getTagClassStyle(tag: string) {
    const tagObj = this.companyTags.find(t => t.value === tag);
    return ' ' + (tagObj?.classStyle || '');
  }
  getTagCount(tag: string) {
    const c = this.data.companies.reduce((acc, company) => acc + (company.tags.includes(tag as CompanyTag) ? 1 : 0), 0);
    return c > 0 ? c : '-';
  }

  getAllTagCount() {
    const l = this.data.companies.length;
    return l > 0 ? l : '-';
  }

  generateSplitButtonItems(rowData: RowData) {
    const items = [];
    const user = this.authService.getLoggedInUser();
    if (rowData.tags.includes(CompanyTag.BASIC) || rowData.tags.includes(CompanyTag.REQUESTED)) {
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.enablePro'),
        icon: 'pi pi-angle-double-up',
        command: () => this.proSwitchClicked(rowData)
      });
    }
    if(rowData.tags.includes(CompanyTag.PRO)){
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.disablePro'),
        icon: 'pi pi-angle-double-down',
        command: () => this.proSwitchClicked(rowData)
      });
    }
    if(rowData.tags.includes(CompanyTag.REQUESTED)){
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.disablePendingRequest'),
        icon: 'pi pi-angle-double-down',
        command: () => this.disablePendingRequest(rowData)
      });
    }
    if(rowData.tags.includes(CompanyTag.TRIAL)){
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.disableProTrial.header'),
        icon: 'pi pi-angle-double-down',
        command: () => {
          this.confirmationService.confirm({
            message: this.translate.instant('manageSubCompanyComponent.table.actions.disableProTrial.confirmMessage'),
            header: this.translate.instant('manageSubCompanyComponent.table.actions.disableProTrial.header'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.disableTrialForCompany(rowData);
            },
            reject: () => {
              //reject action
            }
          });
        }
      });
    }
    if(!(rowData.tags.includes(CompanyTag.PRO)||rowData.tags.includes(CompanyTag.TRIAL))){
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.addTrial'),
        icon: 'pi pi-clock',
        command: () => this.addTrialPeriodClicked(rowData)
      });
    }
    if (!rowData.tags.includes(CompanyTag.PARTNER) && user.clientId === 21) {
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.promoteToPartner.label'),
        icon: 'pi pi-check-circle',
        command: () => {
          this.confirmationService.confirm({
            message: this.translate.instant('manageSubCompanyComponent.table.actions.promoteToPartner.confirmMessage'),
            header: this.translate.instant('manageSubCompanyComponent.table.actions.promoteToPartner.confirmHeader'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.manageSubCompanyService.promoteToPartner(rowData.id, '');
              rowData.tags.push(CompanyTag.PARTNER);
            },
            reject: () => {
              //reject action
            }
          });
        }
      });
    }
    items.push({ separator: true })
    if (rowData.tags.includes(CompanyTag.BLOCKED)) {
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.unblock'),
        icon: 'pi pi-ban',
        command: () => this.blockClientClicked(rowData)
      });
    } else {
      items.push({
        label: this.translate.instant('manageSubCompanyComponent.table.actions.block'),
        icon: 'pi pi-ban',
        command: () => this.blockClientClicked(rowData)
      });
    }

    const currentUser = this.authService.getLoggedInUser();

    if(currentUser.clientId === 21 && currentUser.clientAdmin && rowData.isStripeClient && rowData.pro){
      items.push({
        label: 'Update usage',
        icon: 'pi pi-refresh',
        command: () => {
          this.resetUsageRecordModalData = {
            rowData: rowData,
            timestamp: 0,
            value: 0
          }
          // this.manageSubCompanyService.recalculateClient(rowData.id);
        }
      })
    }

    if(currentUser.clientId === 21 && currentUser.clientAdmin && rowData.isStripeClient && rowData.pro){
      items.push({
        label: 'Pause subscription',
        icon: 'pi pi-refresh',
        command: () => {
          this.pauseSubscriptionModalData = {
            rowData: rowData,
            timestamp: new Date()
          }
          // this.manageSubCompanyService.recalculateClient(rowData.id);
        }
      })
    }

    if(currentUser.clientId === 21 && currentUser.clientAdmin){
      if(rowData.typeOfAccount === TypeOfAccount.NORMAL){
        
        items.push({
          label: 'Change to Self Only',
          icon: 'pi pi-refresh',
          command: () => {
            this.manageSubCompanyService.changeTypeOfAccount(rowData.id, TypeOfAccount.SELF_ONLY);
            //remove TEST tag
            rowData.tags = rowData.tags.filter(t => t !== CompanyTag.TEST);
            rowData.tags.push(CompanyTag.SELF_ONLY);
          }
        })
        items.push({
          label: 'Change to Test',
          icon: 'pi pi-refresh',
          command: () => {
            this.manageSubCompanyService.changeTypeOfAccount(rowData.id, TypeOfAccount.TEST);
            //remove SELF_ONLY tag
            rowData.tags = rowData.tags.filter(t => t !== CompanyTag.SELF_ONLY);
            rowData.tags.push(CompanyTag.TEST);
          }
        })
      }else{
        items.push({
          label: 'Change to Standard',
          icon: 'pi pi-refresh',
          command: () => {
            this.manageSubCompanyService.changeTypeOfAccount(rowData.id, TypeOfAccount.NORMAL);
            //remove TEST and SELF_ONLY tag
            rowData.tags = rowData.tags.filter(t => t !== CompanyTag.TEST && t !== CompanyTag.SELF_ONLY);
          }
        })
      }
    }
      
    return items;
  }

  async changeToTest(rowData: RowData, typeOfAccount: TypeOfAccount){
    try{
      const response = await this.manageSubCompanyService.changeTypeOfAccount(rowData.id, typeOfAccount);
      if(response.message==='SUCCESS'){
        this.messageService.add({
          severity: 'success',
          summary: 'SUCCESS',
          detail: 'SUCCESS',
          life: 10 * 1000
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: response.message,
          detail: response.result,
          life: 10 * 1000
        });
      }
    }catch(e){
      this.messageService.add({
        severity: 'error',
        summary: 'ERROR',
        detail: 'ERROR',
        life: 10 * 1000
      });
    }
  }

  async setSubscriptionPaused(){
    const rowData = this.pauseSubscriptionModalData.rowData;
    const timestamp = this.pauseSubscriptionModalData.timestamp;
    this.pauseSubscriptionModalData = null;
    try{
      const response = await this.manageSubCompanyService.setSubscriptionPaused(rowData.id, timestamp.getTime()/1000);
      if(response.message==='SUCCESS'){
        this.messageService.add({
          severity: 'success',
          summary: 'SUCCESS',
          detail: 'SUCCESS',
          life: 10 * 1000
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: response.message,
          detail: response.result,
          life: 10 * 1000
        });
      }
    }catch(e){
      this.messageService.add({
        severity: 'error',
        summary: 'ERROR',
        detail: 'ERROR',
        life: 10 * 1000
      });
    }
  }

  async setUsageRecord(){
    const rowData = this.resetUsageRecordModalData.rowData;
    const timestamp = this.resetUsageRecordModalData.timestamp;
    const value = this.resetUsageRecordModalData.value;
    this.resetUsageRecordModalData = null;
    try{
      const response = await this.manageSubCompanyService.setUsageRecord(rowData.id, timestamp, value);
      if(response.message==='Success'){
        this.messageService.add({
          severity: 'success',
          summary: 'SUCCESS',
          detail: 'SUCCESS',
          life: 10 * 1000
        });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: response.message,
          detail: response.message,
          life: 10 * 1000
        });
      }
    }catch(e){
      this.messageService.add({
        severity: 'error',
        summary: 'ERROR',
        detail: 'ERROR',
        life: 10 * 1000
      });
    }
  }

}
